<template>
    <form
        method="post"
        class="grid grid-cols-12 gap-6 px-2"
        :class="{ 'disabled-form': loading }"
        @submit.prevent="save"
    >
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-12">
            <label for="form-cabin_number" class="form-label cabin_numbers">
                Номер каюты
                <sup v-if="v$.form.number.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.number.$errors.length" for="form-cabin_number" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.number.$errors[0].$message) }}
            </label>
            <input
                id="form-cabin_number"
                v-model="form.number"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.number.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-4">
            <label for="form-cabin_seats" class="form-label">
                Общее число мест
                <sup v-if="v$.form.number_seats.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.number_seats.$errors.length" for="form-cabin_seats" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.number_seats.$errors[0].$message) }}
            </label>
            <input
                id="form-cabin_seats"
                v-model="form.number_seats"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.number_seats.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-4">
            <label for="form-main_seats" class="form-label">
                Количество главных мест
                <sup v-if="v$.form.main_seats.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.main_seats.$errors.length" for="form-main_seats" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.main_seats.$errors[0].$message) }}
            </label>
            <input
                id="form-main_seats"
                v-model="form.main_seats"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.main_seats.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-4">
            <label for="form-additional_seats" class="form-label">
                Количество добавочных мест
                <sup v-if="v$.form.additional_seats.required" class="text-theme-6">*</sup>
            </label>
            <label
                v-if="v$.form.additional_seats.$errors.length"
                for="form-additional_seats"
                class="form-input-text-error"
            >
                {{ getOverrideMessage(v$.form.additional_seats.$errors[0].$message) }}
            </label>
            <input
                id="form-additional_seats"
                v-model="form.additional_seats"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.additional_seats.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="col-span-12">
            <label class="form-label">Описание</label>
            <CustomWyswig v-model="form.description" />
        </div>

        <div class="col-span-12">
            <label for="deck" class="form-label">Палуба</label>
            <select id="deck" v-model="form.deck_id" class="form-control">
                <option :value="null">Не выбрано</option>
                <option v-for="(deck, deckIdx) in decks" :key="deckIdx" :value="deck.id">
                    {{ deck.title }}
                </option>
            </select>
        </div>

        <div class="col-span-12">
            <label for="cabin-type" class="form-label">Тип каюты</label>
            <select id="cabin-type" v-model="form.cabin_type_id" class="form-control">
                <option :value="null">Не выбрано</option>
                <option v-for="(cabin, cabinIdx) in cabinTypes" :key="cabinIdx" :value="cabin.id">
                    {{ cabin.title }}
                </option>
            </select>
        </div>

        <div class="col-span-12 flex justify-end">
            <button type="button" class="btn btn-secondary mr-2 w-24" @click.prevent="$emit('dataSaved')">
                Отмена
            </button>
            <button type="submit" class="btn btn-primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, maxValue } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'CrudCabin',
    components: { Preloader, CustomWyswig },
    mixins: [errorResponse],
    props: {
        cabinId: {
            type: Number,
            required: true,
        },
    },
    emits: ['dataSaved'],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            form: {
                description: null,
                cabin_type_id: null,
                deck_id: null,
                number_seats: null,
                main_seats: null,
                additional_seats: null,
                number: null,
            },
            cabinTypes: [],
            decks: [],
        };
    },
    validations() {
        return {
            form: {
                number: { required },
                number_seats: { required, numeric, maxValue: maxValue(99) },
                main_seats: { required, numeric, maxValue: maxValue(99) },
                additional_seats: { required, numeric, maxValue: maxValue(99) },
            },
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;

            this.axios
                .get(`/ships/${this.$route.params.id}/cabins/${this.cabinId}/edit`)
                .then((res) => {
                    for (const [key, value] of Object.entries(res.data.cabin)) {
                        this.form[key] = value;
                    }
                    this.cabinTypes = res.data.cabin_types;
                    this.decks = res.data.decks;
                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch cabin category: ' + ex);
                    this.loading = false;
                    this.$router.push('/error-page');
                });
        },
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            const form = formPreparation(this.form);
            console.log('save ~ form:', form);
            this.axios
                .post(`/ships/${this.$route.params.id}/cabins/${this.cabinId}`, form, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then(({ data }) => {
                    this.$emit('dataSaved', true);
                    this.$notify(data.message);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style></style>
